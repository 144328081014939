import L from 'leaflet';
import 'leaflet.fullscreen';
import {addFlash} from "./base";
import 'leaflet/dist/leaflet.css';

// Importy ikon z node_modules – Webpack se postará o cestu
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});

// TOOD: mapmanager

let map;
let formPrefix;
let ticketPanel;
let locationTextInput;
let locationInput;

let dataLayer;
let positionMarker;

const mapaDiv = document.getElementById('mapa');


if (mapaDiv) {
    const map_center = [mapaDiv.dataset.cordY, mapaDiv.dataset.cordX];
    const zoom = mapaDiv.dataset.zoom;
    const mapLocationsType = mapaDiv?.dataset.type;
    const apiKey = process.env.SMAP_API_KEY;

    formPrefix = document.querySelector('.container').dataset.formName || "parking_ticket";
    ticketPanel = document.querySelector('.ticket-panel');
    locationTextInput = ticketPanel.querySelector('#' + formPrefix + '_locationText');
    locationInput = ticketPanel.querySelector('#' + formPrefix + '_location');

    map = L.map('mapa', {
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: 'topleft'
        }
    }).setView(map_center, zoom);

    const tileLayers = {
        'Basic': L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${apiKey}`, {
            minZoom: 0,
            maxZoom: 19,
            attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
        }),
        'Aerial': L.tileLayer(`https://api.mapy.cz/v1/maptiles/aerial/256/{z}/{x}/{y}?apikey=${apiKey}`, {
            minZoom: 0,
            maxZoom: 19,
            attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
        }),
    };
    // init basic map, with layer switch control and default layer with names
    tileLayers['Basic'].addTo(map);
    L.control.layers(tileLayers).addTo(map);

    // Přidání měřítka
    L.control.scale({
        position: 'bottomleft',
        imperial: false,
        maxWidth: 100
    }).addTo(map);

    // add logo
    const LogoControl = L.Control.extend({
        options: {
            position: 'bottomleft',
        },
        onAdd: function (map) {
            const container = L.DomUtil.create('div');
            const link = L.DomUtil.create('a', '', container);

            link.setAttribute('href', 'http://mapy.cz/');
            link.setAttribute('target', '_blank');
            link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
            L.DomEvent.disableClickPropagation(link);

            return container;
        }
    });
    new LogoControl().addTo(map);

    map.on('locationfound', onLocationFound);
    map.on('locationerror', onLocationError);
    map.locate({setView: true, maxZoom: 16});

    // add data to map
    fetch('/order/locations' + (mapLocationsType ? ('?type=' + mapLocationsType) : ''))
        .then((res) => res.json())
        .then((data) => {
            dataLayer = L.geoJSON(data, {
                style,
                onEachFeature
            }).addTo(map);
        });

    // event listener for select of locality
    locationInput.addEventListener('change', (e) => {
        if (!e.cancelable) {    // chage trigered by click to map is cancelable original change event is uncancelabe
            // find geometry and highlight
            iterateAllFeatures(dataLayer, function(feature, layer) {
                if (layer.feature.properties.location_id == e.target.value) {
                    selectFeature({
                        target: layer,
                    });
                };
            });
        }
    });

    const currentPosition = document.querySelector('#show_current_position');
    if (currentPosition) {
        currentPosition.addEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            refreshLocation();
        });
    }
}

function style(feature) {
    return {
        weight: 2,
        opacity: 1,
        color: feature.properties.color,
        fillOpacity: 0.8,
        fillColor: feature.properties.color
    };
}

function highlightFeature(e) {
    const layer = e.target;

    layer.setStyle({
        fillOpacity: 1,
        weight: 4
    });

    layer.bringToFront();
}

function resetHighlight(e) {
    dataLayer.resetStyle(e.target);
}

function selectFeature(e) {
    // zoom to feature
    map.fitBounds(e.target.getBounds());
    e.target.bindPopup("Stojíte v oblasti <b>" + e.target.feature.properties.location_name + "</b>").openPopup();
    // create change events
    locationTextInput.value = e.target.feature.properties.location_name;
    locationInput.value = e.target.feature.properties.location_id;
    // use cancelable to divide from another type of change event in f() fucntion
    locationTextInput.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
    locationInput.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
    // select and scroll to location
    window.scrollTo({
        top: locationTextInput.offsetTop,
        left: 0,
        behavior: 'smooth'
    });
}

function onEachFeature(feature, layer) {
    layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: selectFeature
    });
}

function iterateAllFeatures(layer, callback) {
    if (!layer) return;
    // Pokud je vrstva GeoJSON
    if (layer instanceof L.GeoJSON) {
        layer.eachLayer((featureLayer) => {
            if (featureLayer.feature) {
                callback(featureLayer.feature, featureLayer); // Předá feature a jeho vrstvu
            }
        });
    }
}

function onLocationFound(e) {
    fetch('/order/location?lat=' + e.latlng.lat + '&long=' + e.latlng.lng, {
        headers: {
            "X-CSRF-Token": mapaDiv?.dataset.token
        }
    })
        .then((res) => {
            res.json().then((data) => {
                if (data.id) {
                    locationTextInput.value = data.name;
                    locationInput.value = data.id;
                    // use cancelable to divide from another type of change event in f() fucntion
                    locationTextInput.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
                    locationInput.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
                }
            });
        })
        .catch((err) => console.error(err));
    // TODO: dopracovat označení lokality dle pozice
    // console.log(e.latlng);
    // console.log(dataLayer);
    // // iterateAllFeatures(dataLayer, function(feature, layer) {
    //     selectFeature({
    //         latlng: e.latlng,
    //         target: layer,
    //     });
    // });
    //
    //{
    //     target: dataLayer,
    //     latlng: e.latlng
    // });
    // setPositionMarker(e.latlng);
    // console.log(positionMarker);
    //map.setView(e.latlng, 15);
    setPositionMarker(e.latlng, "Vaše poloha");
}
function onLocationError(e) {
    console.log("Location error", e);
}

function refreshLocation() {
    // refresh location
    map.locate({
        enableHighAccuracy: true,
    });
    // Ošetření chyby při získávání polohy
    map.on('locationerror', (e) => {
        onLocationError(e);
        addFlash('warning', 'Pro zobrazení vaší polohy, povolte prosím přístup k poloze ve vašem prohlížeči.', {autoHide: true});
    });
}

function setPositionMarker(position, label = null) {
    removePostionMarker();
    if (!position) {
        return null;
    }
    positionMarker = L.marker([position.lat, position.lng]).addTo(map);
    if (label) {
        positionMarker.bindPopup(label); // .openPopup();
    }
    map.setView([position.lat, position.lng], 15);
    return positionMarker;
}

function getPositionMarkerLatLng() {
    return positionMarker ? positionMarker.getLatLng() : null;
}

function removePostionMarker() {
    if (positionMarker) {
        positionMarker.remove();
    }
    positionMarker = null;
}

// export default function map_init() {
//     let mapaDiv = document.getElementById('mapa');
//     if (!mapaDiv) {
//         return;
//     }
// }
